<template>
  <div class="previous-test">
    <Header></Header>
    <!-- Will Show all previous test Title Total, Start Time,Duration,And edit test button -->
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="card-container" v-for="(item, index) in li" :key="index">
          <div>
            <h3>{{ item["title"] }}</h3>
          </div>
          <div class="icons">
            <div title="Edit revision" @click="redirect_to_edit(index)">
              <span
                class="iconify"
                data-icon="carbon:view"
                data-inline="false"
                data-width="48"
                data-height="48"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-for="(item, index) in li" :key="index" class="box container">
      <div class="row mt-3 pt-3 justify-content-center">
        <div class="col d-flex justify-content-center">
          <h6>{{ item["title"] }}</h6>
        </div>
      </div>
      <div class="row my-3 py-3">
        <div class="col d-flex justify-content-center">
          <button class="btn" @click="redirect_to_edit(index)">Open</button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import db from "../firebase.js";
import store from "@/store";
import { defineAsyncComponent } from "vue";

const Header = defineAsyncComponent(() => import("@/components/Header"));

export default {
  components: {
    Header,
  },
  props: ["revision_name"],
  mounted() {
    console.log(this.revision_name);
    db.collection("revisions")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // (this.li)
          let di = doc.data();
          di["revision_id"] = doc.id;
          if (
            di["category"] == this.revision_name ||
            this.revision_name == "All revisions"
          )
            this.li.push(di);
        });
      });
    store.state.loading = false;
  },
  data() {
    return {
      li: [],
    };
  },
  methods: {
    redirect_to_edit(index) {
      this.$router.push({
        name: "ViewRevision",
        params: { revision_id: this.li[index]["revision_id"] },
      });
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,500,700");
.previous-test {
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%, red);
  background-attachment: fixed;
  position: absolute;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.box h6 {
  color: white;
  font-family: "Oswald", sans-serif;
}

.date-time {
  color: rgb(198, 207, 240);
}

.iconify:hover {
  color: #c11919;
}

.iconify {
  margin-left: 10px;
}

.card-container {
  border-radius: 40px;
  transition: 0.45s;
  width: 300px;
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 40px 50px;
  box-shadow: 10px 10px 2px rgb(110 0 0 / 25%);
  position: relative;
  margin: 50px 10px;

  background-color: rgb(242, 227, 227);
}

.card-container:hover {
  transform: scale(0.9, 0.9);
  border-radius: 50px;
  box-shadow: 10px 10px 5px 6px rgba(110, 0, 0, 0.25);
}

.icons {
  padding: 10px;
  width: min-content;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto auto;
}

svg {
  margin-right: 10px;
}

* {
  color: rgb(148, 22, 0);
  text-align: center;
}

h2 {
  margin-bottom: 10px;
}
</style>
